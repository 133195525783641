import React from "react";
import styled, { keyframes } from "styled-components";
import colors from "../style/colors";
import P1 from "../typography/P1.js";
import anime from "animejs";
import { Link } from "gatsby";

const loadingAni = keyframes`
  50% {
    opacity: .35;
  }
`;

const ButtonWrapper = styled.div`
  text-decoration: none;
  display: inline-block;
  flex-shrink: 0;
  opacity: ${props => (props.disabled ? 0.8 : 1)};
  filter: saturate(${props => (props.disabled ? 0.3 : 1)});
  a {
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: ${props => (props.big ? "1.2em" : "1em")};
    text-align: center;
    font-weight: 700;
    border: none;
    border-radius: 0.5em;
    padding: ${props => (!props.wide ? "0.5em 1.6em" : "0.5em 3em")};
    width: ${props => (props.full ? "100%" : "auto")};

    transform: scale(1.01);

    background: ${props => (props.color ? colors[props.color] : colors.accent1)}24;

    ${props =>
      props.noMobile &&
      `
    @media (max-width: 640px) {
      display: none !important;
    }

  `}

    p {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      color: ${props => (props.color ? colors[props.color] : colors.accent1)};
      margin: 0;
      svg {
        width: 1.5em;
        height: 1.5em;
        path {
          fill: ${props => (props.color ? colors[props.color] : colors.accent1)};
        }
        margin-left: -0.4em;
        margin-right: 0.4em;
      }
      line-height: 1.5;
    }

    cursor: ${props => (props.disabled ? "auto" : "pointer")};
    outline: none;
    user-select: none;

    animation: ${props => props.loading && loadingAni} 1s 0.3s linear infinite;
    animation-timing-function: ease-in-out;

    &:active {
      opacity: ${props => (props.secondary ? 0.6 : 0.8)};
    }

    ${props =>
      props.solid &&
      `
    background: ${props.color ? colors[props.color] : "white"};
    p {color: ${props.color ? "white" : colors.ultra_dark};}
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  `}

    ${props =>
      props.solidInverted &&
      `
    background: ${props.color ? colors[props.color] : "white"};
    p {color: ${props.color ? "black" : colors.ultra_dark};}
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  `}

    ${props =>
      props.small &&
      `
    font-size: .8em;
    p {font-weight: 500;}
  `}
  }
`;

const Button = props => {
  const { href, to, onClick, children, disabled, ...otherProps } = props;

  let domNode = null;

  const hover = disabled
    ? {}
    : {
        on: () => {
          anime({
            targets: domNode,
            scale: 1.05
          });
        },
        off: () => {
          anime({
            targets: domNode,
            scale: 1.001
          });
        }
      };

  return (
    <>
      {(() => {
        if (!to) {
          return (
            <ButtonWrapper
              className="button"
              disabled={disabled}
              ref={node => (domNode = node)}
              onMouseEnter={hover.on}
              onMouseLeave={hover.off}
              onClick={onClick}
              target="_blank"
              {...otherProps}
            >
              <a href={href}>
                <P1>{children}</P1>
              </a>
            </ButtonWrapper>
          );
        } else {
          return (
            <ButtonWrapper
              className="button"
              disabled={disabled}
              ref={node => (domNode = node)}
              onMouseEnter={hover.on}
              onMouseLeave={hover.off}
              onClick={onClick}
              target="_blank"
              {...otherProps}
            >
              <Link to={to}>
                <P1>{children}</P1>
              </Link>
            </ButtonWrapper>
          );
        }
      })()}
    </>
  );
};

export default Button;
